import { decamelize } from "humps"

import { EmberApiError, parseError } from "api/errors"

import { Checklist, ChecklistItem, ChecklistSummary } from "types/checklist"
import { Unsubscribable } from "types/observable"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"

export enum ChecklistCompletion {
  NOT_STARTED = "not_started",
  PARTIALLY_COMPLETED = "partially_completed",
  COMPLETED = "completed",
}

export interface ChecklistSelectionQuery {
  // If adding fields here, consider also adding them to `urlStoreForChecklists` so that they're
  // mirrored in the URL on the scheduled work index page
  type?: string[]
  completion?: ChecklistCompletion[]
  vehicleId?: number[]
  locationId?: number[]
  limit?: number
  sortColumnId?: string
  sortReversed?: boolean
}

export interface fetchChecklistsParams {
  query: ChecklistSelectionQuery
  onSuccess: (checklists: ChecklistSummary[], total: number) => void
  onError: (error: EmberApiError) => void
}

export function fetchChecklists({
  query,
  onSuccess,
  onError,
}: fetchChecklistsParams): Unsubscribable {
  return fetchFromAPIBase({
    method: "GET",
    path:
      "/v1/maintenance/checklists/" +
      getQueryString({
        ...query,
        sortColumnId: query.sortColumnId && decamelize(query.sortColumnId),
      }),
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.checklists, response.total)
    } else {
      onError(parseError(response))
    }
  })
}

export function fetchChecklistByUid({
  checklistUid,
  onSuccess,
  onError,
}: {
  checklistUid: string
  onSuccess: (checklist: Checklist) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    method: "GET",
    path: `/v1/maintenance/checklists/${checklistUid}/`,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.checklist)
    } else {
      onError(parseError(response))
    }
  })
}

export interface CreateChecklistPayload {
  type: string
  vehicleId: number
  title?: string
  deadline: string
  activityIds?: number[]
}

export function createChecklist({
  payload,
  onSuccess,
  onError,
}: {
  payload: CreateChecklistPayload
  onSuccess: (checklist: Checklist) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    method: "POST",
    path: "/v1/maintenance/checklists/",
    body: payload,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.checklist)
    } else {
      onError(parseError(response))
    }
  })
}

export interface ChecklistItemPayload {
  chosenOption?: string[]
  freeText?: string
  number?: number
  date?: string
  fileUploads?: string[]
}

export interface ValidationMessage {
  level: "warning" | "error"
  message: string
}

export function updateChecklistItem({
  checklistUid,
  itemUid,
  payload,
  onSuccess,
  onError,
}: {
  checklistUid: string
  itemUid: string
  payload: ChecklistItemPayload
  onSuccess: (
    checklistItem: ChecklistItem,
    validationMessages: ValidationMessage[]
  ) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    method: "POST",
    path: `/v1/maintenance/checklists/${checklistUid}/items/${itemUid}/`,
    body: payload,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.checklistItem, response.validationMessages)
    } else {
      onError(parseError(response))
    }
  })
}

export function markChecklistAsComplete({
  checklistUid,
  onSuccess,
  onError,
}: {
  checklistUid: string
  onSuccess: (checklist: Checklist) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    method: "POST",
    path: `/v1/maintenance/checklists/${checklistUid}/`,
    body: { action: "mark_as_complete" },
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.checklist)
    } else {
      onError(parseError(response))
    }
  })
}

export interface ChecklistModificationPayload {
  vehicleId?: number
  deadline?: string
  activityIds?: number[]
}

export function modifyChecklist({
  checklistUid,
  payload,
  onSuccess,
  onError,
}: {
  checklistUid: string
  payload: ChecklistModificationPayload
  onSuccess: (checklist: Checklist) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    method: "PUT",
    path: `/v1/maintenance/checklists/${checklistUid}/`,
    body: payload,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.checklist)
    } else {
      onError(parseError(response))
    }
  })
}

export function fetchChecklistTypes({
  onSuccess,
  onError,
}: {
  onSuccess: (checklistTypes: string[]) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    method: "GET",
    path: "/v1/maintenance/checklist-types/",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.checklistTypes)
    } else {
      onError(parseError(response))
    }
  })
}
