/** Group elements in an array by an arbitrary function
 *
 *     > groupBy(["abc", "abc", "def", "cc"], (element) => {return element.length})
 *     {3: ["abc", "abc", "def"], 2: ["cc"]}
 *
 * @param array An array of objects to be grouped
 * @param func A function which operates on each element in array, returning the grouping key
 * @returns A mapping of the grouping key (returned by func) and the elements with the same grouping key
 */
export function groupBy<T, U extends keyof any>(
  array: Array<T>,
  func: (element: T) => U
): Record<U, T[]> {
  return array.reduce(
    function (result, element) {
      ;(result[func(element)] = result[func(element)] || []).push(element)
      return result
    },
    {} as Record<U, T[]>
  )
}

export type Comparable = number | string

/**
 * Sort an array based on the values returned by the given `key` function (like
 * the Python `sorted` function). The `key` function should return either
 * numbers or strings.
 *
 * Like the standard `Array.sort`, this function both modifies the given array
 * and returns it.
 */
export function sortBy<T>(
  array: T[],
  key: (element: T) => Comparable,
  reverse = false
): T[] {
  return array.sort((a: T, b: T): number => {
    const aKey = key(a)
    const bKey = key(b)
    if (aKey < bKey) {
      return reverse ? 1 : -1
    } else if (aKey > bKey) {
      return reverse ? -1 : 1
    } else {
      return 0
    }
  })
}

/**
 * Sort an array by multiple values.
 * Similar to `sortBy`, but accepts an array of keys to sort by.
 */
export function sortByKeys<T>(
  array: T[],
  keys: ((element: T) => Comparable)[]
): T[] {
  return array.sort((a: T, b: T): number => {
    for (const key of keys) {
      const aKey = key(a)
      const bKey = key(b)
      if (aKey < bKey) {
        return -1
      } else if (aKey > bKey) {
        return 1
      }
    }
    return 0
  })
}

/**
 * Takes an object and a function, and returns a copy of the object, where the keys
 * are unchanged, and the the values are the result of calling the given function on
 * each respective value in the original object.
 *
 * For instance this:
 *
 *     remapValues({left: 10, right: 20}, (n) => n * 2)
 *
 * returns `{left: 20, right: 40}`.
 *
 * The provided function can optionally accept a second parameter, which is the
   corresponding key in the object (a string).
 */
export function remapValues<K extends string | number | symbol, V1, V2>(
  record: Record<K, V1>,
  map: (V1, K) => V2
): Record<K, V2> {
  const remapped = {} as Record<K, V2>
  for (const [key, value] of Object.entries(record)) {
    remapped[key] = map(value, key)
  }
  return remapped
}
