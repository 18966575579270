import { Auth } from "@aws-amplify/auth/lib/Auth"
import * as env from "config/env"
import { camelizeKeys } from "humps"

import { parseError } from "api/errors"

export async function getJwtToken(): Promise<string | undefined> {
  const user = await Auth.currentUserPoolUser()
  if (!user || user.isNetworkError) {
    return undefined
  }
  const session = await Auth.currentSession()
  return session?.getIdToken().getJwtToken()
}

type Headers = {
  [key: string]: string
}

async function getAuthHeaders(): Promise<Headers> {
  const jwtToken = await getJwtToken()
  const headers: Headers = {}
  if (!jwtToken) {
    return headers
  }
  headers.Authorization = `Bearer ${jwtToken}`
  return headers
}

type FetchOptions = {
  method: string
  path: string
  body?: object
}

export async function authFetch<T>({
  method,
  path,
  body,
}: FetchOptions): Promise<T> {
  const headers = await getAuthHeaders()
  return plainFetch<T>({ method, path, headers, body })
}

export async function plainFetch<T>({
  method,
  path,
  headers,
  body,
}: FetchOptions & { headers?: Headers }): Promise<T> {
  const result = await fetch(`${env.API_BASE_URL}${path}`, {
    method,
    headers: body
      ? { ...headers, "Content-Type": "application/json" }
      : headers,
    body: body ? JSON.stringify(body) : undefined,
  })

  if (!result.ok) {
    const errorJson = await parseJson(result)
    throw parseError(errorJson)
  }

  const json = await parseJson(result)
  return camelizeKeys(json) as T
}

async function parseJson(result: Response): Promise<any> {
  try {
    return await result.json()
  } catch (e: unknown) {
    throw new Error(
      `Failed to parse response as JSON: ${isError(e) ? e.message : "Unknown error occurred"}`
    )
  }
}

function isError(error: unknown): error is Error {
  return error instanceof Error
}
