import { camelize } from "humps"

type ValueString = {
  value: number
  singular: string
  plural: string
}

export function addQuantifier(valueString: ValueString) {
  if (valueString.value > 1 || valueString.value == 0) {
    return valueString.value + " " + valueString.plural
  } else if (valueString.value == 1) {
    return valueString.value + " " + valueString.singular
  }
}

export function arrayToString(valueStrings: ValueString[]) {
  const stringArray: string[] = []

  valueStrings.map((valueString) => {
    if (valueString.value > 0) {
      const quantifier = addQuantifier(valueString)
      if (quantifier !== undefined) {
        stringArray.push(quantifier)
      }
    }
  })

  let combinedString = stringArray.join(", ")
  combinedString = combinedString.replace(/,(?=[^,]*$)/, " and")

  return combinedString
}

interface penniesInToPoundsStrOptions {
  removeZeroes?: boolean
}

export function penniesInToPoundsStr(
  pennies: number,
  { removeZeroes = false }: penniesInToPoundsStrOptions = {}
): string {
  let text = `£${(pennies / 100).toFixed(2)}`
  if (removeZeroes) {
    text = text.replace(/\.00$/, "")
  }
  return text
}

function twoDecimalPlacesRegexMatch(num: string) {
  return num.match(/^[0-9]*(\.[0-9]{0,2})?$/)
}

export function validateNumberHasUpToTwoDecimalPlaces(num: number): boolean {
  if (num) {
    return !!twoDecimalPlacesRegexMatch(num.toString())
  }
  // Note that this would also return false if the number is 0 or 0.00
  return false
}

export function poundsToPennies(pounds: number): number {
  const amountWithDecimal = pounds.toFixed(2)
  return parseInt(amountWithDecimal.replace(".", ""))
}

export function capitalizeFirstLetter(text: string | null) {
  if (text == null) {
    return null
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function toTitleCase(text: string) {
  return text.toLowerCase().replace(/\b\w/g, (match) => match[0].toUpperCase())
}

export function snakeCaseToTitleCase(snakeCase: string): string {
  // Takes an snake-cased string such as "REFUND_FOR_ALTERNATIVE_TRANSPORT" and
  // returns a titlecased version of the string, suitable for display to the
  // user (e.g. "Refund For Alternative Transport")
  return `${snakeCase}`
    .toLowerCase()
    .split("_")
    .map(capitalizeFirstLetter)
    .join(" ")
    .replace(/^Non /, "Non-")
}

export function snakeCaseToSentenceCase(
  snakeCase: string | null
): string | null {
  // Takes an snake-cased string such as "REFUND_FOR_ALTERNATIVE_TRANSPORT" and
  // returns a sentence case version of the string, suitable for display to the
  // user (e.g. "Refund for alternative transport")
  if (snakeCase == null) {
    return null
  }
  const lowercaseString = snakeCase
    .toLowerCase()
    .split("_")
    .join(" ")
    .replace(/^Non /, "Non-")
  return capitalizeFirstLetter(lowercaseString)
}

const UID_REGEXP = new RegExp(
  "[0-9a-f]{8}" +
    "-[0-9a-f]{4}" +
    "-[0-9a-f]{4}" +
    "-[0-9a-f]{4}" +
    "-[0-9a-f]{12}"
)

export function camelizeKeysExceptUids(value: any) {
  if (
    typeof value == "string" ||
    typeof value === "number" ||
    typeof value === "undefined" ||
    value === null ||
    value === true ||
    value === false
  ) {
    return value
  } else if (value instanceof Array) {
    return value.map(camelizeKeysExceptUids)
  } else {
    const result = {}
    for (const key of Object.keys(value)) {
      // this is the key bit
      const newKey = UID_REGEXP.test(key) ? key : camelize(key)
      result[newKey] = camelizeKeysExceptUids(value[key])
    }
    return result
  }
}

export function paramIsSetToTrue(rawValue: string | null) {
  if (!rawValue) {
    return false
  }
  const normalised = `${rawValue}`.toLowerCase()
  return normalised == "true" || normalised == "1"
}
