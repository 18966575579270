import { queryOptions } from "@tanstack/react-query"
import { decamelizeKeys } from "humps"

import {
  type Location,
  type MapLocation,
  type NaptanLocation,
} from "types/location"
import { Unsubscribable } from "types/observable"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"
import { authFetch } from "utils/new-fetch-utils"

import { EmberApiError, parseError } from "./errors"

interface LocationSearchRequest {
  type?: string
  includeOrigins?: boolean
  includeDestinations?: boolean
}

interface fetchLocationsParams {
  request: LocationSearchRequest
  onSuccess: (locations: Location[]) => void
  onError: (error: EmberApiError) => void
}

function sortLocationsByRegionAndDetailedName(locations: Location[]) {
  const sorted = locations.sort(function (a, b) {
    // Make sure sort is case insensitive
    const titleA = a.regionName.toLowerCase()
    const descriptionA = a.detailedName.toLowerCase()
    const titleB = b.regionName.toLowerCase()
    const descriptionB = b.detailedName.toLowerCase()
    return titleA < titleB
      ? -1
      : titleA > titleB
        ? 1
        : descriptionA < descriptionB
          ? -1
          : 1
  })
  return sorted
}

export function fetchLocations({
  request = {
    type: "all",
    includeOrigins: true,
    includeDestinations: true,
  },
  onSuccess,
  onError,
}: fetchLocationsParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/locations/${getQueryString(request)}`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(sortLocationsByRegionAndDetailedName(response))
    } else {
      onError(parseError(response))
    }
  })
}

type GetMapLocationsOptions = {
  minLat: number
  minLon: number
  maxLat: number
  maxLon: number
  source?: "internal" | "naptan"
}

export async function getMapLocations(
  params: GetMapLocationsOptions
): Promise<MapLocation[]> {
  const results = await authFetch<MapLocation[]>({
    method: "GET",
    path: `/v1/locations/map/${getQueryString(decamelizeKeys(params))}`,
  })

  return results.sort((a, b) =>
    a.detailedName.toLowerCase().localeCompare(b.detailedName.toLowerCase())
  )
}

type SearchMapLocationsOptions = {
  query: string
}

export async function searchMapLocations(
  params: SearchMapLocationsOptions
): Promise<MapLocation[]> {
  const results = await authFetch<MapLocation[]>({
    method: "GET",
    path: `/v1/locations/map/search/${getQueryString(params)}`,
  })

  return results.sort((a, b) =>
    a.detailedName.toLowerCase().localeCompare(b.detailedName.toLowerCase())
  )
}

export async function getLocationDetails(
  locationId: number
): Promise<Location> {
  return await authFetch<Location>({
    method: "GET",
    path: `/v1/locations/${locationId}/`,
  })
}

export async function getNaptanLocationDetails(
  locationId: number
): Promise<NaptanLocation> {
  return await authFetch<NaptanLocation>({
    method: "GET",
    path: `/v1/locations/naptan/${locationId}/`,
  })
}

export async function createLocation(
  location: Partial<Location>
): Promise<Location> {
  return await authFetch<Location>({
    method: "POST",
    path: "/v1/locations/",
    body: decamelizeKeys(location),
  })
}

export async function updateLocation(
  location: Partial<Location>
): Promise<Location> {
  const { id, ...locationWithoutId } = location
  return await authFetch<Location>({
    method: "PUT",
    path: `/v1/locations/${id}/`,
    body: decamelizeKeys(locationWithoutId),
  })
}

export const mapLocationsQuery = ({
  minLat,
  minLon,
  maxLat,
  maxLon,
  source,
}: GetMapLocationsOptions) => {
  return queryOptions({
    queryKey: [
      "map-locations",
      { minLat, minLon, maxLat, maxLon, source },
    ] as const,
    queryFn: () => getMapLocations({ minLat, minLon, maxLat, maxLon, source }),
    // Retain the prior data when refetching. This means that we continue to display
    // the already fetched map locations even when moving around the map.
    placeholderData: (prev: MapLocation[] | undefined) => prev,
    // By default, keep data in the cache for 10 minutes.
    staleTime: 10 * 60 * 1000,
  })
}
