// @ts-strict-ignore
import {
  addDays,
  differenceInSeconds,
  format,
  isAfter,
  parseISO,
} from "date-fns"
import moment, { Moment } from "moment-timezone"

import { type DateRange } from "components/generic/date-time/ember-date-range-picker"

import { type Location } from "types/location"
import { type LocationTime } from "types/location-time"
import { type TimeOffBooking } from "types/person"
import { type QuoteType } from "types/quote"
import { type Trip, type TripInfo } from "types/trip"

import { getTripDeparture } from "./pass-utils"

// TIMEZONES: a novel
//
// All dates and times displayed to the user on our website must be timezone-aware. We don't leave
// anything up to the browser's or the server's local time.
//
//
// Generally we want all times to be displayed in the timezone of the associated location. So for
// instance stop times are displayed in the timezone of the stop's location, and shift times are
// displayed in the timezone of the shift's hub. The `toLocalTime` function below can be used for
// this.
//
// The backend API sends all datetimes to the frontend as ISO strings that include a timezone
// marker. The timezone in those strings is typically UTC, but ultimately it doesn't matter which
// timezone it is, it doesn't need to match the timezone used to display the datetime on the
// frontend -- it just serves to anchor the time to an exact moment.
//
// The API also gives us the named timezone of each location (e.g. "Europe/London"), so for
// timestamps that relate to a location, we don't need to hardcode the timezone.
//
//
// However! There are several contexts where we have to display a datetime that is not tied to a
// specific location:
//
// <> Times that are related to a trip, but not to a specific stop
//
//    This includes the timestamps for driver messages, passenger messages, stops cancellations. In
//    most cases these don't relate to a specific stop, but they are always displayed within the
//    context of a specific trip.
//
//    For these datetimes we use the timezone of the 1st stop on the trip. This will allow us to
//    operate in multiple markets, as long as we don't have timezone-crossing trips. Once we do have
//    those, we'll need something smarter.
//
// <> Times used in search queries
//
//    When you're on the trip admins page and looking at trips from, say, 2023-03-27, what timezone
//    should we use to interpret that date? The search is not associated with any location or trip.
//    The same applies to the dates that customers select when searching for tickets.
//
//    Eventually these queries should be done using naive datetimes, since we want to select trips
//    based on their departure time in each trip's respective timezone. We intend to do that, but it
//    will require backend changes, and YAGNI for now.
//
//    So for now we hardcode queries to use the timezone named below in `getDefaultTimezone`. Once
//    we operate in multiple timezones, we can revisit this.
//
// <> Views that potentially span multiple timezones (other than trip views)
//
//    When we display a calendar timeline view of driver activities, we need to pick a single
//    timezone for the whole view -- we don't want for instance to have events that are shown as
//    aligned in the timeline chart but that don't actually start at the same time.
//
//    For such views at the moment we fall back to the timezone named in `getDefaultTimezone`. Once
//    we move to multi-timezone markets we might want to have a GUi widget that lets the user picks
//    the timezone they want to use. Maybe it could default to their browser's timezone.
//
// <> Other times
//
//    We have a few other places where we display times that are not tied to a location or to a
//    trip, and are not timezone-less queries. For instance, the timestamps shown in a customer's
//    credit history modal.
//
//    For now these too are hardcoded to the timezone returned by `getDefaultTimezone`. This should
//    work in a multi-market setup as long as each market has only a single timezone. As with the
//    other scenarios just described above, once we expand into a multi-timezone market, we'll need
//    to rethink this further.

export const getDefaultTimezone = () =>
  // Pages & components that don't currently have a smart way of assigning a timezone to a timestamp
  // (the vehicles page, the search form, the driver rota timeline, etc) can use the site-wide
  // timezone. This should work as long as we're in single-timezone markets.
  //
  // Eventually this could read from global state, environment variables, etc. As explained above,
  // this should be good enough for a multi-market setup, as long as each market is in a single
  // timezone.
  //
  // Once we enter multi-timezone markets, this global variable will need to go away, and every use
  // of it will need to be made smarter so that it can pick a sensible timezone for every time
  // displayed. And that's partly the point of this function -- every use of it marks a piece of
  // code where the code will need to pick a timezone.
  "Europe/London"

// We can infer a timezone from any of these
export type TimezoneContext =
  | Location
  | LocationTime
  | LocationTime[]
  | TripInfo
  | Trip
  | TimeOffBooking
  | { timezone: string }
  | string

/**
 * Takes a timezone-aware datetime, a timezone, and a Moment.js format spec, and returns a string
 * representing the given datetime, in the given timezone.
 */
export const toLocalTime = (
  datetime: string | Date | Moment,
  context: TimezoneContext,
  format: string
): string => {
  const timezone = getTimezoneFromContext(context)
  return moment.tz(datetime, timezone).format(format)
}

export const getTimezoneFromContext = (context: TimezoneContext): string => {
  // This implements the logic described in the long comment above
  if (!context || typeof context == "string") {
    // @ts-expect-error: we just checked the type
    return validateTimezoneString(context)
  }

  // TripInfo object
  if ("route" in context) {
    if (!context.route) {
      throw new Error(
        "The given TripInfo object doesn't have route information"
      )
    }
    context = context.route
  }

  // List of stops: take the 1st time
  if (Array.isArray(context)) {
    if (context.length == 0) {
      throw new Error("Cannot use empty array as context")
    }
    context = context[0]
  }

  // Trip: use its origin
  if ("origin" in context) {
    context = context.origin
  }

  // LocationTime: take its Location
  if ("location" in context) {
    context = context.location
  }

  // @ts-expect-error: by now we've whittled it down to sth that has a timezone attribute
  return validateTimezoneString(context.timezone)
}

const validateTimezoneString = (timezone: string): string => {
  if (!timezone) {
    throw new Error(`Missing timezone: ${timezone == "" ? '""' : timezone}`)
  }
  return timezone
}

// this is set aside so that it can be mocked
export const getCurrentTime = () => moment()

export const formatDateForQuery = (
  date: string,
  includeFollowingMorning?: boolean
) => {
  return {
    from: moment.tz(date, getDefaultTimezone()).format(),
    to: moment
      .tz(date, getDefaultTimezone())
      .add(1, "days")
      .add(includeFollowingMorning === true ? 4 : 0, "hours")
      .format(),
  }
}

export const formatAsDate = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "dddd D MMMM YYYY")

export const formatAsMachineReadableDate = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "YYYY-MM-DD")

export const formatAsAbbreviatedDate = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "ddd D MMM YYYY")

export const formatAsMinimizedDate = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "D MMM YYYY")

export const formatAsAbbreviatedDateWithoutYear = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "ddd D MMM")

export const formatAsAbbreviatedDateWithYear = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "ddd D MMM YYYY")

export const formatAsMediumDate = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "ddd D MMM")

export const formatAsShortDate = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "D MMMM")

export const formatAsShortestDate = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "D MMM")

export const formatAsDay = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "ddd")

export const formatAsTime = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "HH:mm")

export const formatAsPreciseTime = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "HH:mm:ss")

export const formatAsShortDateTime = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "D MMMM [at] HH:mm")

export const formatAsShortDateTimeRange = (
  start: string | Date,
  end: string | Date,
  context: TimezoneContext
): string =>
  `${toLocalTime(start, context, "D MMM HH:mm")} - ${toLocalTime(
    end,
    context,
    "D MMM HH:mm"
  )}`

export const formatAsShortDateTimeRangeWithDayName = (
  start: string | Date,
  end: string | Date,
  context: TimezoneContext
): string =>
  `${toLocalTime(start, context, "ddd D MMM HH:mm")} - ${toLocalTime(
    end,
    context,
    "ddd D MMM HH:mm"
  )}`

export const formatAsShortRelativeDateTime = (
  date: string | Date,
  context: TimezoneContext,
  options: {
    // shortening to just the weekday (e.g. "Wed at 12:00") is only recommended in contexts where
    // all dates are in the past (e.g. chat messages). If dates are a mix of past and future dates,
    // displaying them as just the weekday and time confuses people.
    allowShorteningToJustWeekday?: boolean
  } = {}
): string => {
  const timezone = getTimezoneFromContext(context)
  const asMoment = moment.tz(date, timezone)
  const now = getCurrentTime().tz(timezone)
  if (now.isSame(asMoment, "day")) {
    return asMoment.format("HH:mm")
  } else if (
    now.isSame(asMoment, "week") &&
    options?.allowShorteningToJustWeekday
  ) {
    return asMoment.format("ddd [at] HH:mm")
  } else if (now.isSame(asMoment, "year")) {
    // NB when you're in January this will put the year on December dates, even though they're
    // recent. We could decide to display the year on dates that are > 365 days in the past instead
    return asMoment.format("D MMM [at] HH:mm")
  } else {
    return asMoment.format("D MMM YYYY [at] HH:mm")
  }
}

export const formatAsShortDateTimeWithYear = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "DD/MM/YYYY HH:mm")

export const formatAsPreciseDateTime = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "HH:mm:ss on D MMMM YYYY")

export const formatAsDateTime = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "HH:mm on D MMMM YYYY")

export const formatAsAbbreviatedDateTime = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "HH:mm ddd D MMM YYYY")

export const formatAsMonth = (
  date: string | Date,
  context: TimezoneContext
): string => toLocalTime(date, context, "MMMM YYYY")

export const formatUTCAsExactDateTime = (date: string | Date): string =>
  moment.tz(date, "Etc/UTC").format("HH:mm:ss on D MMMM YYYY")

export const formatAsDuration = (
  startDate: string | Date,
  endDate: string | Date,
  shorthand = true
): string => {
  const minutes = moment(endDate).diff(moment(startDate), "minutes")
  let minutesSymbol = "m"
  let hoursSymbol = "h"
  if (!shorthand) {
    minutesSymbol = " minute"
    hoursSymbol = " hour"
  }
  if (minutes < 60) {
    return `${minutes}${minutesSymbol}`
  } else {
    const hours = Math.floor(minutes / 60)
    const minutes_remainder = minutes % 60
    return `${hours}${hoursSymbol} ${minutes_remainder}${minutesSymbol}`
  }
}

export function formatAsRelativeTime(
  actualString: string | Date,
  scheduledString: string | Date
) {
  let actualDate: Date
  let scheduledDate: Date

  if (actualString instanceof Date) {
    actualDate = actualString
  } else {
    actualDate = parseISO(actualString)
  }

  if (scheduledString instanceof Date) {
    scheduledDate = scheduledString
  } else {
    scheduledDate = parseISO(scheduledString)
  }

  const secsLate = Math.abs(differenceInSeconds(actualDate, scheduledDate))
  const absSecsLate = Math.abs(secsLate)
  const typeDescription = actualDate < scheduledDate ? "early" : "late"

  if (secsLate < 10 && secsLate > -10) {
    return "on time"
  } else if (absSecsLate < 60) {
    // Up to a minute
    return `${absSecsLate} sec${absSecsLate == 1 ? "" : "s"} ${typeDescription}`
  } else if (absSecsLate < 60 * 60) {
    // Up to an hour
    const mins = Math.floor(absSecsLate / 60)
    return `${mins} min${mins == 1 ? "" : "s"} ${typeDescription}`
  } else if (absSecsLate < 60 * 60 * 24) {
    // Up to a day
    const hoursAmount = Math.floor(absSecsLate / 60 / 60)
    const minsAmount = Math.floor(absSecsLate / 60) - 60 * hoursAmount
    return `${hoursAmount} hr${hoursAmount == 1 ? "" : "s"} ${minsAmount} min${
      minsAmount == 1 ? "" : "s"
    } ${typeDescription}`
  } else {
    const daysAmount = Math.floor(absSecsLate / 60 / 60 / 24)
    const hoursAmount = Math.floor(absSecsLate / 60 / 60) - 24 * daysAmount
    return `${daysAmount} day${daysAmount == 1 ? "" : "s"} ${hoursAmount} hr${
      hoursAmount == 1 ? "" : "s"
    } ${typeDescription}`
  }
}

export function compareDepartureTimes(
  a: QuoteType | TripInfo,
  b: QuoteType | TripInfo
) {
  if (getTripDeparture(a) < getTripDeparture(b)) {
    return -1
  }
  if (getTripDeparture(a) > getTripDeparture(b)) {
    return 1
  }
  return 0
}

export function formatDateRange(start: string, end: string): string {
  const startMoment = moment.tz(start, getDefaultTimezone())
  const endMoment = moment.tz(end, getDefaultTimezone())
  if (startMoment.isSame(endMoment, "day")) {
    // On same date
    return `from ${startMoment.format("HH:mm")} to ${endMoment.format(
      "HH:mm"
    )} on ${startMoment.format("DD MMMM")}`
  } else {
    // Different dates
    return `from ${moment(start).format("HH:mm")} on ${moment(start).format(
      "DD MMM YYYY"
    )}
    to ${moment(end).format("HH:mm")} on ${moment(end).format("DD MMM YYYY")}`
  }
}

export function formatShortTimeRange(start: string, end: string): string {
  const startMoment = moment.tz(start, getDefaultTimezone())
  const endMoment = moment.tz(end, getDefaultTimezone())
  return `${startMoment.format("DD MMMM")} ${startMoment.format(
    "HH:mm"
  )} - ${endMoment.format("HH:mm")}`
}

// A wrapper around `moment().isSame()` that handles null values
export function datesEqual(d1: Date | string, d2: Date | string): boolean {
  if (!d1) {
    return !d2
  } else if (!d2) {
    return !d1
  } else {
    return moment(d1).isSame(d2)
  }
}

// Min date is an optional ISO date string when you don't want to allow bookings before
// This criteria will be applied on top of normal criteria of bookings can't be before
// today in UK time
export const bookingFromDate = (minDate?: string): string => {
  const now = getCurrentTime().tz(getDefaultTimezone())
  if (minDate == null) {
    return now.startOf("day").format("YYYY-MM-DD")
  } else {
    return moment.max(now, moment(minDate)).startOf("day").format("YYYY-MM-DD")
  }
}

// Max date is an optional ISO date string when you don't want to allow bookings before
// This criteria will be applied on top of normal criteria of bookings can't be before
// today in UK time
export const bookingToDate = (maxDate?: string): string => {
  const bookingsOpenUntil = getLatestBookableDateOverall()
  if (maxDate == null) {
    return bookingsOpenUntil.startOf("day").format("YYYY-MM-DD")
  } else {
    return moment
      .min(bookingsOpenUntil, moment(maxDate))
      .startOf("day")
      .format("YYYY-MM-DD")
  }
}

// This is set aside so that it can be mocked in tests
export const getLatestBookableDateOverall = () =>
  moment.tz("2025-02-28", getDefaultTimezone())

// Rota is only accurate from this date
export const driverRotaPublishedFromDate = (): string => {
  return moment("2022-02-21").format("YYYY-MM-DD")
}

// Rota is only published until this date. Assignment may change beyond this with no notice
export const driverRotaPublishedToDate = (): Date => {
  return getCurrentTime().add(27, "days").endOf("week").add(1, "day").toDate()
}

export const getDateArray = (dateRange: DateRange): string[] => {
  if (isAfter(dateRange.start, dateRange.end)) return []
  const formatted_start = format(dateRange.start, "yyyy-MM-dd")
  const formatted_end = format(dateRange.end, "yyyy-MM-dd")
  const result = [formatted_start]
  while (result[result.length - 1] != formatted_end) {
    result.push(
      format(addDays(new Date(result[result.length - 1]), 1), "yyyy-MM-dd")
    )
  }
  return result
}

// Convert the day of a date to the ISO weekday.
// JavaScript's getDay() returns 0 for Sunday, so we need to adjust it to return 7 for Sunday.
export function getISOWeekday(dt: Date): number {
  const dayOfWeek = dt.getDay()
  return dayOfWeek === 0 ? 7 : dayOfWeek
}
